import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import Select from '@paljs/ui/Select';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  checkNumber,
  dateFormat,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  ADMIN,
  getPageAccessFromState
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const QuotationPage = () => {
  const state = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(state);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  const [quotationData, setQuotationData] = useState();
  const [itemOptions, setItemOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [defaultItem, setDefaultItem] = useState([]);
  const [defaultSupplier, setDefaultSupplier] = useState([]);
  const [quotationDate, setQuotationDate] = useState(dateFormat(new Date()));
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    authCheck(state.authData);
    getApi();
    getItems();
    getSuppliers();
  }, []);

  const getItems = () => {
    axios
      .get(baseUri() + 'items', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.itemCode + ' - ' + item.attributes.itemName, value: item.id });
        });
        setItemOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSuppliers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=3', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setSupplierOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getApi = () => {
    showLoader();
    axios
      .get(baseUri() + 'quotations?populate=*&sort[0]=id:desc', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setQuotationData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const [rate, setRate] = useState('');
  const [item, setItem] = useState('');
  const [supplier, setSupplier] = useState('');
  const [pickId, setPickId] = useState('');

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'rate':
        setRate(checkNumber(event.target, '8.2'));
        break;
      case 'quotationDate':
        setQuotationDate(event.target.value);
        break;
    }
  };

  const itemCodeChange = (event) => {
    setItem(event.value);
    setDefaultItem({ label: event.label, value: event.value });
    pullDownReset('item');
  };

  const itemSupplierChange = (event) => {
    setSupplier(event.value);
    setDefaultSupplier({ label: event.label, value: event.value });
    pullDownReset('supplier');
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setRate('');
    setPickId('');
    setDefaultItem([]);
    setDefaultSupplier([]);
    setItem('');
    setSupplier('');
    setQuotationDate(dateFormat(new Date()));
    resetRequired();
  };

  const postApi = () => {
    const postData = {
      data: {
        rate,
        item,
        supplier,
        quotationDate,
      },
    };
    let validate = requireCheck(postData);
    if (!validate) {
      return false;
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'quotations/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'quotations/', postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const editQuotation = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(baseUri() + `quotations/${id}?populate=*`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setSave(pageAccess.edit === 1);
        setRate(response.data.data.attributes.rate);
        setItem(response.data.data.attributes.item.data.id);
        setSupplier(response.data.data.attributes.supplier.data.id);
        setPickId(response.data.data.id);
        setQuotationDate(dateFormat(response.data.data.attributes.quotationDate));
        setDefaultItem({
          label:
            response.data.data.attributes.item.data.attributes.itemCode +
            ' - ' +
            response.data.data.attributes.item.data.attributes.itemName,
          value: response.data.data.attributes.item.data.id,
        });
        setDefaultSupplier({
          label:
            response.data.data.attributes.supplier.data.attributes.userCode +
            ' - ' +
            response.data.data.attributes.supplier.data.attributes.name,
          value: response.data.data.attributes.supplier.data.id,
        });
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteQuotation = (id) => {
    const removeQuotation = window.confirm('Would you like to delete this quotation?');
    if (removeQuotation) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'quotations/' + id, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editQuotation(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteQuotation(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
    },
    {
      name: 'Item',
      selector: (row) =>
        `${
          row.attributes.item.data
            ? row.attributes.item.data.attributes.itemCode + ' - ' + row.attributes.item.data.attributes.itemName
            : ''
        }`,
      sortable: true,
      maxWidth: '40%',
    },
    {
      name: 'Supplier',
      selector: (row) =>
        `${
          row.attributes.supplier.data
            ? row.attributes.supplier.data.attributes.userCode + ' - ' + row.attributes.supplier.data.attributes.name
            : ''
        }`,
      sortable: true,
      maxWidth: '40%',
    },
    {
      name: 'Rate',
      selector: (row) => numFormatter(row.attributes.rate, true),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Date',
      selector: (row) => `${dateFormat(row.attributes.quotationDate)}`,
      sortable: true,
      maxWidth: '10%',
    },
  ];
  const filterColumns = [
    'item.data.attributes.itemCode',
    'item.data.attributes.itemName',
    'supplier.data.attributes.userCode',
    'supplier.data.attributes.name',
    'rate',
    'quotationDate',
  ];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = quotationData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="Quotation Master" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Quotation Master</header>
            <CardBody className="cardbody">
              <label>
                Select Item <span className="red">*</span>
              </label>
              <SelectField
                fullWidth
                onChange={(e) => itemCodeChange(e)}
                options={itemOptions}
                name="item"
                id="item"
                placeholder="Select Item *"
                value={defaultItem}
                className="pull-down itemCode require"
              />
              <label>
                Select Supplier <span className="red">*</span>
              </label>
              <SelectField
                fullWidth
                onChange={(e) => itemSupplierChange(e)}
                options={supplierOptions}
                name="supplier"
                id="supplier"
                placeholder="Select Supplier *"
                value={defaultSupplier}
                className="pull-down supplierCode require"
              />
              <label>
                Rate <span className="red">*</span>
              </label>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => handleChange(e)}
                  placeholder="Rate *"
                  name="rate"
                  maxLength="11"
                  value={rate}
                  className="rate require"
                />
              </Input>
              <label>
                Quotation Date <span className="red">*</span>
              </label>
              <Input fullWidth size="Small">
                <input
                  type="date"
                  name="quotationDate"
                  placeholder="Quotation Date *"
                  onChange={(e) => handleChange(e)}
                  value={quotationDate}
                  className="quotationDate require"
                />
              </Input>
              <Button
                status="Success"
                type="button"
                disabled={!isSave}
                className="btnrad"
                shape=""
                halfWidth
                onClick={postApi}
              >
                Save
              </Button>
              &nbsp; &nbsp;
              <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                Reset
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Quotation Master</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default QuotationPage;
